<template>
  <div class="gradeLearning">
    <div class="top-box">
      <div class="check-box">
        <el-select
          v-model="subjectOptionsVal"
          placeholder="请选择"
          @change="changeOption"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="check-box-right">
          <el-select
            v-model="optionsTimeVal"
            placeholder="请选择"
            @change="changeOption"
          >
            <el-option
              v-for="item in optionsTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="optionYearVal"
            placeholder="请选择"
            @change="subjectlistbygrade"
          >
            <el-option
              v-for="item in optionYear"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="examPaperIds"
            multiple
            collapse-tags
            placeholder="全部考试"
            class="set-input morelist"
            @change="setComType()"
          >
            <!-- <el-option label="所有考试" value=""> </el-option> -->
            <el-option
              v-for="item in examList"
              :key="item.examPaperId"
              :label="item.examName"
              :value="item.examPaperId"
            >
            </el-option>
          </el-select>
          <!-- <el-button type="primary" class="btn-all">导出</el-button> -->
        </div>
      </div>
      <information
        v-if="comBoxType"
        :child-obj="childObj"
        :option-year="optionYear"
      ></information>
    </div>

    <knowTable v-if="comBoxType" :child-obj="childObj"></knowTable>
    <wrongBox
      v-if="comBoxType"
      :child-obj="childObj"
      :option-year="optionYear"
      :exam-list="examList"
      :exam-paper-ids="examPaperIds"
      @setPaperIds="setPaperIds"
    ></wrongBox>
  </div>
</template>

<script>
import { optionsTime, examTypeOptions } from "@/core/util/constdata";
import { getGradeList } from "@/core/api/school/schoolgradeclass";
import { subjectlistbygrade } from "@/core/api/school/school";
import { getExamListByTimeRange } from "@/core/api/resource/learningPaper";

import wrongBox from "@/components/learning/wrongBox.vue";
import knowTable from "@/components/learning/knowTable.vue";
import information from "@/components/learning/topMsg.vue";
export default {
  name: "GradeLearning",
  components: {
    wrongBox,
    knowTable,
    information,
  },
  data() {
    return {
      //和错题组件对应的字段
      childObj: {},
      comBoxType: false,
      subjectOptions: [],
      subjectOptionsVal: "",
      optionYearVal: "",
      optionYear: [],
      optionsTimeVal: 0,
      optionsTime: optionsTime,
      examTypeOptions: examTypeOptions,

      topMsg: {},
      menuList: [
        { label: "按时间", isClick: true },
        { label: "按章节", isClick: false },
      ],
      totalPage: 0,
      pageSize: 14,
      pageIndex: 1,
      value: "",
      examList: [],
      examPaperIds: [],
    };
  },
  created() {
    this.getOption();

    //初始化组件状态设为false
    this.wrongBoxType = false;
  },
  methods: {
    setPaperIds(val) {
      // console.log(val);
      this.examPaperIds = [...val];
      this.setComType();
    },
    getExamListByTimeRange() {
      let data = {
        subjectId: this.subjectOptionsVal,
        timeRange: this.optionsTimeVal,
        gradeId: this.optionYearVal,
        levelId: this.optionYear.filter((i) => i.value == this.optionYearVal)[0]
          .level,
        // examType: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,99"
      };
      this.examList = [];
      this.examPaperIds = [];
      getExamListByTimeRange(data).then((res) => {
        // console.log(res);
        this.examList = res.data.data;
        let paperIds = [];
        this.examList.map((index) => {
          paperIds.push(index.examPaperId);
          let str = this.examTypeOptions.filter(
            (item) => index.type == item.value
          )[0].label;
          index.examName =
            index.examDate + " " + index.examName + "（" + str + "）";
        });

        this.setComType(paperIds.join(","));
      });
    },
    // setExamList(list) {
    //   // console.log(list);
    //   this.examPaperIds = [];
    //   this.examList = list;
    // },
    // 根据年级获取科目
    subjectlistbygrade() {
      this.subjectOptions = [];
      let data = {
        gradeId: this.optionYearVal,
      };
      subjectlistbygrade(data).then((res) => {
        let isTrue =
          res.data.data.filter((item) => item.id == this.subjectOptionsVal)
            .length == 0;
        if (isTrue) {
          if (res.data.data.filter((item) => item.id == 2).length == 1) {
            this.subjectOptionsVal = 2;
          } else {
            this.subjectOptionsVal = res.data.data[0].id;
          }
        }
        // console.log(res.data.data);
        res.data.data.forEach((index) => {
          this.subjectOptions.push({
            value: index.id,
            label: index.name,
          });
        });
        this.getExamListByTimeRange();
      });
    },
    changeOption() {
      this.getExamListByTimeRange();
    },
    setComType(paperIds) {
      this.comBoxType = false;
      this.childObj = {
        subjectId: this.subjectOptionsVal,
        timeRange: this.optionsTimeVal,
        gradeId: this.optionYearVal,
        level: this.optionYear.filter((i) => i.value == this.optionYearVal)[0]
          .level,
        examPaperIds: paperIds ? paperIds : this.examPaperIds.join(","),
        paperIds: paperIds,
        // gradeId: 1
      };
      this.comBoxType = true;
    },
    // 获取年级
    getOption() {
      let data = {
        isGradeLearning: 1,
      };
      getGradeList(data).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            this.optionYear.push({
              value: item.id,
              label: item.name,
              level: item.level,
            });
            if (item.classCount && !this.optionYearVal) {
              this.optionYearVal = item.id;
            }
          });
          if (!this.optionYearVal) {
            this.optionYearVal = res.data.data[0].id;
          }

          this.subjectlistbygrade();
        }
      });
    },
  },
};
</script>
<style lang="scss">
.gradeLearning {
  .el-table td,
  .el-table th {
    padding: 8px 0;
  }
  .morelist {
    // overflow-x: scroll;
    width: 120px;

    input:-ms-input-placeholder {
      color: #606266 !important;
    }
    input::placeholder {
      color: #606266;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
      .el-tag {
        color: #606266;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.gradeLearning {
  width: 1220px;
  margin: 0 auto;

  .top-box {
    border: 1px solid #dddddd;
    padding: 12px;
    margin: 20px 0;
    p {
      margin-bottom: 6px;
    }
    .check-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .el-select {
        width: 180px;
      }
      .check-box-right {
        .btn-all {
          padding: 8px 18px;
        }
        .el-select {
          padding-right: 12px;
        }
      }
    }
  }
}
</style>
